import Vue from 'vue'
import VueRouter from 'vue-router'
import blindbox from '../views/blindbox/index.vue'
import newyear from '../views/newyear/index.vue'
import laborday from '../views/laborday/index.vue'
import lovetree from '../views/lovetree/index.vue'
import midyear from '../views/midyear/index.vue'

Vue.use(VueRouter)

const routes = [
	// 
	{
	  path: '/',
	  name: 'lovetree',
	  meta: {
	    title: '爱情树',
	    keepAlive: true
	  },
	  component: lovetree
	},
	// 盲盒抽奖
  {
    path: '/blindbox',
    name: 'blindbox',
    meta: {
      title: '小盲盒大惊喜',
      keepAlive: true
    },
    component: blindbox
  },
  {
    path: '/prize',
    name: 'prize',
    meta: {
      title: '奖池'
    },
    component: () => import(/* webpackChunkName: "prize" */ '../views/blindbox/prize.vue')
  },
  {
    path: '/record',
    name: 'record',
    meta: {
      title: '抽奖记录'
    },
    component: () => import(/* webpackChunkName: "record" */ '../views/blindbox/record.vue')
  },
  {
    path: '/result',
    name: 'result',
    meta: {
      title: '抽奖结果'
    },
    component: () => import(/* webpackChunkName: "result" */ '../views/blindbox/result.vue')
  },
	// 新春活动
	{
	  path: '/newyear',
	  name: 'index',
	  meta: {
	    title: '虎年送福，充值得大礼！',
	    keepAlive: true
	  },
	  component: newyear
	},
	{
	  path: '/ranking',
	  name: 'ranking',
	  meta: {
	    title: '充值排行榜'
	  },
	  component: () => import(/* webpackChunkName: "ranking" */ '../views/newyear/ranking.vue')
	},
	{
	  path: '/activityExplain',
	  name: 'activityExplain',
	  meta: {
	    title: '活动说明'
	  },
	  component: () => import(/* webpackChunkName: "october" */ '../views/newyear/activityExplain.vue')
	},
	{
	  path: '/rankingExplain',
	  name: 'rankingExplain',
	  meta: {
	    title: '排行说明'
	  },
	  component: () => import(/* webpackChunkName: "rankingExplain" */ '../views/newyear/rankingExplain.vue')
	},
	// 五一活动
	{
	  path: '/laborday',
	  name: 'index',
	  meta: {
	    title: '五一大回馈！',
	    keepAlive: true
	  },
	  component: laborday
	},
	{
	  path: '/richExplain',
	  name: 'richExplain',
	  meta: {
	    title: '奖励说明'
	  },
	  component: () => import(/* webpackChunkName: "richExplain" */ '../views/laborday/richExplain.vue')
	},
	{
	  path: '/goddessExplain',
	  name: 'goddessExplain',
	  meta: {
	    title: '奖励说明'
	  },
	  component: () => import(/* webpackChunkName: "goddessExplain" */ '../views/laborday/goddessExplain.vue')
	},
	// 618活动
	{
	  path: '/midyear',
	  name: 'midyear',
	  meta: {
	    title: '618狂欢日，限时有礼！',
	    keepAlive: true
	  },
	  component: midyear
	},
	{
	  path: '/entrance',
	  name: 'entrance',
	  meta: {
	    title: '活动入口'
	  },
	  component: () => import(/* webpackChunkName: "goddessExplain" */ '../views/valentineDay/entrance.vue')
	},
	{
	  path: '/reward',
	  name: 'reward',
	  meta: {
	    title: '奖励发放'
	  },
	  component: () => import(/* webpackChunkName: "goddessExplain" */ '../views/popup/reward.vue')
	},
	{
	  path: '/useExplain',
	  name: 'useExplain',
	  meta: {
	    title: '使用说明'
	  },
	  component: () => import(/* webpackChunkName: "goddessExplain" */ '../views/useExplain/index.vue')
	},
	{
	  path: '/miPhones',
	  name: 'miPhones',
	  meta: {
	    title: '小米'
	  },
	  component: () => import(/* webpackChunkName: "goddessExplain" */ '../views/useExplain/mi.vue')
	},
	{
	  path: '/huaweiPhones',
	  name: 'huaweiPhones',
	  meta: {
	    title: '华为'
	  },
	  component: () => import(/* webpackChunkName: "goddessExplain" */ '../views/useExplain/huawei.vue')
	},
	{
	  path: '/vivoPhones',
	  name: 'vivoPhones',
	  meta: {
	    title: 'vivo'
	  },
	  component: () => import(/* webpackChunkName: "goddessExplain" */ '../views/useExplain/vivo.vue')
	},
	{
	  path: '/oppoPhones',
	  name: 'oppoPhones',
	  meta: {
	    title: 'oppo'
	  },
	  component: () => import(/* webpackChunkName: "goddessExplain" */ '../views/useExplain/oppo.vue')
	},
	{
	  path: '/meizuPhones',
	  name: 'meizuPhones',
	  meta: {
	    title: 'meizu'
	  },
	  component: () => import(/* webpackChunkName: "goddessExplain" */ '../views/useExplain/meizu.vue')
	},
	{
	  path: '/iPhone',
	  name: 'iPhone',
	  meta: {
	    title: '苹果'
	  },
	  component: () => import(/* webpackChunkName: "goddessExplain" */ '../views/useExplain/iPhone.vue')
	},
	// 充值抽奖活动
	{
	  path: '/luckDraw',
	  name: 'luckDraw',
	  meta: {
	    title: '充值抽大奖'
	  },
	  component: () => import(/* webpackChunkName: "goddessExplain" */ '../views/luckDraw/index.vue')
	},
	{
	  path: '/recharge',
	  name: 'recharge',
	  meta: {
	    title: '充值列表'
	  },
	  component: () => import(/* webpackChunkName: "goddessExplain" */ '../views/valentineDay/recharge.vue')
	},
	{
	  path: '/valentineDay',
	  name: 'valentineDay',
	  meta: {
	    title: '约惠七夕'
	  },
	  component: () => import(/* webpackChunkName: "goddessExplain" */ '../views/valentineDay/index.vue')
	},
	{
	  path: '/consult',
	  name: 'consult',
	  meta: {
	    title: '帮助与咨询'
	  },
	  component: () => import(/* webpackChunkName: "goddessExplain" */ '../views/consult/help.vue')
	},
	{
	  path: '/swindle',
	  name: 'swindle',
	  meta: {
	    title: '防诈骗宣传'
	  },
	  component: () => import(/* webpackChunkName: "goddessExplain" */ '../views/consult/swindle.vue')
	},
	{
	  path: '/question',
	  name: 'question',
	  meta: {
	    title: '问题'
	  },
	  component: () => import(/* webpackChunkName: "goddessExplain" */ '../views/consult/question.vue')
	}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
	/* 路由发生变化修改页面title */
	if (to.meta.title) {
		document.title = to.meta.title
	}
	next()
})

export default router
