import request from '@/request/index.js'

// -----  盲盒抽奖  -----

// 盲盒信息初始化
export function initializeApi(data) {
		return request({
			url: `/props/list/${data}`,
			method: 'GET'
		})
}

// 开启盲盒
export function openBlindBoxApi(data) {
	return request({
		url: '/lotteryPrize/draw',
		method: 'POST',
		data
	})
}

// 奖池列表
export function prizePoolApi(data) {
	return request({
		url: '/lotteryPrize/prizePool',
		method: 'POST',
		data
	})
}

// 获奖记录列表
export function recordListApi(data) {
	return request({
		url: '/lotteryRecord/page',
		method: 'POST',
		data
	})
}

// 获奖记录轮播
export function carouselListApi(data) {
	return request({
		url: '/lotteryRecord/carousel',
		method: 'POST',
		data
	})
}

// -----  新春活动  -----

// 首页排名和积分
export function getUserRankApi(data) {
	return request({
		url: '/lottery/getUserRank',
		method: 'POST',
		data
	})
}

// 活动排行榜单
export function getRankListApi(data) {
	return request({
		url: '/lottery/getRankList',
		method: 'POST',
		data
	})
}

// -----  五一活动  -----

// 活动榜单
export function getLabordayRankApi(data) {
	return request({
		url: '/lottery/getRankList',
		method: 'POST',
		data
	})
}

// 个人排名
export function getMyRankApi(data) {
	return request({
		url: 'lottery/getUserRank',
		method: 'POST',
		data
	})
}

// -----  618活动  -----

// 排行榜
export function getCallRankRecordApi(data) {
	return request({
		url: 'lottery/getCallRankRecord',
		method: 'GET',
		params: data
	})
}

// 大礼包
export function get618ActivityConfigApi() {
	return request({
		url: 'config/get618ActivityConfig',
		method: 'GET'
	})
}

// 签到列表
export function getActivitySignInListApi() {
	return request({
		url: 'lottery/getActivitySignInList',
		method: 'GET'
	})
}

// 签到
export function activitySignInApi() {
	return request({
		url: 'lottery/activitySignIn',
		method: 'POST'
	})
}

// -----  7月充值抽奖活动  -----
// 充值列表
export function getActivityMidYearConfigApi(data) {
	return request({
		url: 'config/getActivityMidYearConfig',
		method: 'GET',
		params: data
	})
}

// 轮播列表
export function getUserPartakeRecordsApi(data) {
	return request({
		url: 'activity/mid-year/getUserPartakeRecords',
		method: 'POST',
		data
	})
}

// 剩余抽奖次数
export function getUserChanceApi() {
	return request({
		url: 'activity/mid-year/getUserChance',
		method: 'POST'
	})
}

// 抽奖
export function getLuckDrawApi() {
	return request({
		url: 'activity/mid-year/lottery',
		method: 'POST'
	})
}

// 个人中奖纪录
export function getUserLotteryRecordsApi(data) {
	return request({
		url: 'activity/mid-year/getUserLotteryRecords',
		method: 'POST',
		data
	})
}

// 中奖龙虎榜
export function getLotteryRecordsApi(data) {
	return request({
		url: 'activity/mid-year/getLotteryRecords',
		method: 'POST',
		data
	})
}

// 用户余额
export function getUserAccountApi() {
	return request({
		url: 'u/getUserAccount',
		method: 'GET'
	})
}

// -----  七夕情人节  -----

// 排行榜
export function getQixiRankListApi(data) {
	return request({
		url: 'lottery/getCallRankRecordByQixi',
		method: 'GET',
		params: data
	})
}

// 签到列表
export function getQixiSignInListApi() {
	return request({
		url: 'tanabata/list',
		method: 'GET'
	})
}

// 充值列表
export function getQixiRechargeListApi(data) {
	return request({
		url: 'tanabata/recharge',
		method: 'GET',
		params: data
	})
}

// 签到
export function getQixiReceiveApi(data) {
	return request({
		url: 'tanabata/receive',
		method: 'GET',
		params: data
	})
}
