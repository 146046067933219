<template>
	<div class="lump">
		<div class="love-tree">
			<div v-show="state" id="svga"></div>
			<div @click="closePage()" class="drop-down">
				<img src="@/assets/images/lovetree/drop-down.png" alt="">
			</div>
			<div class="tips-box">
				<div class="num">
					<img src="@/assets/images/lovetree/icon-03.png" alt="">
					<span>0</span>
				</div>
				<div class="icon">
					<div @click="changeMask('rule')">
						<img src="@/assets/images/lovetree/icon-01.png" alt="">
						<p>玩法</p>
					</div>
					<div @click="changeMask('record')">
						<img src="@/assets/images/lovetree/icon-02.png" alt="">
						<p>记录</p>
					</div>
				</div>
			</div>
			<div class="notice-box">
				<img src="@/assets/images/lovetree/icon-05.png" alt="">
				<van-swipe class="notice-swipe" :autoplay="1800" loop vertical :show-indicators="false" :touchable="false">
					<van-swipe-item v-for="(item, index) in carouselList" :key="index" class="notice">
						<p>恭喜 <span class="nick-name">{{item.nickName}}</span> 获得 <span class="gift">{{item.prizeName}}</span> x{{item.prizeNum}}</p>
					</van-swipe-item>
				</van-swipe>

			</div>
			<div @click="changeMask('pool')" class="gift-pool">
				<img src="@/assets/images/lovetree/icon-04.png" alt="">
			</div>
			<div class="tree-box">
				<img class="tree" src="@/assets/images/lovetree/tree.png" alt="">
				<div @click="putWater" class="watering">
					<p>{{gear == 1 ? 20 : 200}}钻石</p>
					<img v-show="gear == 1" src="@/assets/images/lovetree/one.png" alt="">
					<img v-show="gear == 10" src="@/assets/images/lovetree/ten.png" alt="">
				</div>
			</div>
			<div class="gear-btn">
				<div @click="changeGear(1)" :class="gear == 1 ? 'active' : ''">
					<p class="num">x1</p>
					<p>灌溉</p>
				</div>
				<div @click="changeGear(10)" :class="gear == 10 ? 'active' : ''">
					<p class="num">x10</p>
					<p>灌溉</p>
				</div>
			</div>
			<!-- 获得奖励 -->
			<div v-show="mask == 'gift'" class="mask">
				<p class="tips">恭喜您！获得以下奖励！</p>
				<div class="gift-box">
					<img class="shine" src="@/assets/images/lovetree/gift-bj.png" alt="">
					<div class="gift-item">
						<div v-show="gear == 1" class="one">
							<img :src="imgHost+prizeUrl" alt="">
						</div>
						<ul v-show="gear == 10">
							<li v-for="(item, index) in prizeUrl" :key="index">
								<img :src="imgHost+item.prizeUrl" alt="">
							</li>
						</ul>
					</div>
				</div>
				<p @click="changeMask('')" class="ok-btn">好的</p>
			</div>
			<!-- 礼物池 -->
			<div v-show="mask == 'pool'" class="mask">
				<p class="tips">礼物池</p>
				<ul class="gift-pool">
					<li v-for="(item, index) in prizePool" :key="index">
						<img :src="imgHost+item.prizeUrl" alt="">
					</li>
				</ul>
				<p @click="changeMask('')" class="ok-btn pool-btn">好的</p>
			</div>
			<!-- 抽奖记录 -->
			<div v-show="mask == 'record'" class="mask">
				<p class="tips">抽奖记录</p>
				<div class="record">
					<div class="record-list">
						<van-list v-model="loading" :finished="finished" :immediate-check ='false' finished-text="暂无更多抽奖记录" @load="getRecordList">
							<van-cell v-for="item in recordList" :key="item.id">
								<div class="record-item">
									<p>{{item.createTime}}抽奖获得</p>
									<img :src="imgHost+item.prizeUrl" alt="">
								</div>
							</van-cell>
						</van-list>
					</div>
				</div>
				<p @click="changeMask('')" class="ok-btn">好的</p>
			</div>
			<!-- 玩法 -->
			<div v-show="mask == 'rule'" class="mask">
				<p class="tips">玩法</p>
				<div class="explain">
					<p>1、灌溉前必须要先拥有水滴或钻石，水滴可通过官方各种活动获得。</p>
					<p>2、点击按钮“灌溉”即开始给幸运树灌溉,幸运树获得水滴滋润后,会有机会给施肥者馈礼物</p>
					<p>3、灌溉1次,会结出1个礼物果实。灌溉10次,会结出10个礼物果实。以此类推</p>
					<p>4、通过幸运树回馈奖池中礼物。</p>
					<p>5、奖池中礼物官方会适时调整礼物优化。</p>
					<p>6、灌溉一次爱情小树1水滴或20钻石一次</p>
					<p>7、爱情树出奖概率</p>
					<ul class="chance-list">
						<li class="title">
							<p>礼物名称</p>
							<p>钻石价值</p>
							<p>出奖率</p>
						</li>
						<li v-for="(item, index) in chanceList" :key="index">
							<p>{{item.giftName}}</p>
							<p>{{item.price}}</p>
							<p>{{item.percent}}</p>
						</li>
						<li style="justify-content: space-between;">
							<p>总计</p>
							<p>100.00%</p>
						</li>
					</ul>
				</div>
				<p @click="changeMask('')" class="ok-btn">好的</p>
			</div>
		</div>
	</div>
</template>

<script>
	import SVGA from "svgaplayerweb"
	import Cookies from 'js-cookie'
	import { 
		initializeApi,
		carouselListApi,
		prizePoolApi,
		recordListApi,
		openBlindBoxApi } from '@/request/api'
	export default {
		data() {
			return {
				gear: 1,
				mask: '',
				state: false,
				token: '',
				lotteryId: 1,  // 活动ID
				propsId: '',  // 道具ID
				imgBase: '',
				carouselList: [],
				prizePool: [],
				imgHost: process.env.VUE_APP_IMG_URL,
				formData: {
					lotteryId: '',
					propsId: '',
					current: 1,
					size: 11
				},
				loading: false,
				finished: false,
				recordList: [],
				prizeUrl: '',
				chanceList: [
					{ giftName: '一只红玫瑰', price: '10', percent: '53.00%' },
					{ giftName: '香吻', price: '18', percent: '40.86%' },
					{ giftName: '玩偶狗', price: '52', percent: '1.10%' },
					{ giftName: '玩偶兔子', price: '52', percent: '1.10%' },
					{ giftName: '心心相印', price: '52', percent: '1.10%' },
					{ giftName: '珍珠', price: '52', percent: '1.10%' },
					{ giftName: '香水', price: '100', percent: '0.50%' },
					{ giftName: '口红', price: '128', percent: '0.32%' },
					{ giftName: '爱心玫瑰', price: '188', percent: '0.20%' },
					{ giftName: '分红玩偶熊', price: '188', percent: '0.20%' },
					{ giftName: '提拉米苏', price: '188', percent: '0.20%' },
					{ giftName: '钻戒', price: '200', percent: '0.10%' },
					{ giftName: '冰激凌', price: '288', percent: '0.04%' },
					{ giftName: '比基尼', price: '288', percent: '0.04%' },
					{ giftName: '口罩', price: '388', percent: '0.03%' },
					{ giftName: 'love气球', price: '388', percent: '0.03%' },
					{ giftName: '生日蛋糕', price: '388', percent: '0.03%' },
					{ giftName: '水晶鞋', price: '388', percent: '0.03%' },
					{ giftName: '威士忌', price: '520', percent: '0.01%' },
					{ giftName: '520气球', price: '520', percent: '0.01%' }
				]
			}
		},
		methods: {
			changeGear(gear) {
				this.gear = gear
			},
			changeMask(mask, state) {
				switch(mask) {
					case 'record':
						this.loading = false
						this.finished = false
						this.recordList = []
						this.formData.current = 1
						this.getRecordList()
						break;
				}
				this.mask = mask
			},
			// 灌溉
			putWater() {
				if (this.gear == 1) {
					this.state = true
					this.playSvg('http://qn.qs520.mobi/lovetree-one.svga')
					this.stopSvg(3000)
				} else {
					this.state = true
					this.playSvg('http://qn.qs520.mobi/lovetree-ten.svga')
					this.stopSvg(4000)
				}
				openBlindBoxApi({
					lotteryId: this.lotteryId,
					propsId: this.propsId,
					type: this.gear == 1 ? 1 : 2
				}).then(res => {
					this.prizeUrl = this.gear == 1 ? res.data[0].prizeUrl : res.data
					this.getInitialize()
					setTimeout(() => {
						this.mask = 'gift'
					}, 1200)
				}).catch(err => {
					this.stopSvg(2000)
					this.$toast(err.message)
				})
			},
			// 雨滴动画
			playSvg(url) {
				//一定要使用$nextTick，等到页面加载完成再处理数据，否则会找不到页面元素，报Undefind的错误
				this.$nextTick(()=> {
					const player = new SVGA.Player('#svga')
					const parser = new SVGA.Parser('#svga')
					//这里使用动态加载的方式，加载tableData返回的svga源（例如：http://a.svga)
					parser.load(url, (videoItem)=> {
						player.setVideoItem(videoItem)
						player.startAnimation()
						player.clearsAfterStop = true //player有很多属性，根据需要设置
					})
				})
			},
			// 停止动画
			stopSvg(time) {
				const player = new SVGA.Player('#svga')
				setTimeout(() => {
					this.state = false
					player.stopAnimation(true)
					player.clear()
				}, time)
			},
			// 获取初始化数据
			getInitialize(state) {
				initializeApi(this.lotteryId).then(res => {
					this.propsId = res.data[0].id
					this.formData.lotteryId = res.data[0].lotteryId
					this.formData.propsId = res.data[0].id
					this.getCarouselList(this.propsId)
					if (state == 1) {
						this.getPrizeList(this.propsId)
					}
				}).catch(err => {
					this.$toast(err.message)
				})
			},
			// 获奖记录轮播
			getCarouselList(id) {
				carouselListApi({
					current: 1,
					lotteryId: this.lotteryId,
					propsId: id,
					size: 20
				}).then(res => {
					this.carouselList = res.data.records
				}).catch(err => {
					this.$toast(err.message)
				})
			},
			// 奖池列表
			getPrizeList(id) {
				prizePoolApi({
					lotteryId: this.lotteryId,
					propsId: id,
				}).then(res => {
					this.prizePool = res.data
				}).catch(err => {
					this.$toast(err.message)
				})
			},
			// 获奖记录列表
			getRecordList() {
				recordListApi(this.formData).then(res => {
					if (this.recordList.length === res.data.total) {
						this.finished = true
						return
					}
					this.formData.current ++
					res.data.records.map(item => {
						this.recordList.push(item)
						return this.recordList
					})
					this.loading = false
				}).catch(err => {
					this.finished = true
					this.loading = false
					this.$toast(err.message)
				})
			},
			closePage() {
				let ua = navigator.userAgent.toLowerCase()
				if (ua.indexOf('android') != -1) {
					androidWebKit.close()
				} else if (ua.indexOf('iphone') != -1) {
					window.webkit.messageHandlers.closeWindow.postMessage("关闭窗口")
				}
			}
		},
		mounted() {
			this.token = this.$route.query.token
			this.lotteryId = this.$route.query.lotteryId
			Cookies.set('token', this.token, { expires: 1 })
			this.getInitialize(1)
		}
	}
</script>

<style lang="stylus" scoped>
	.lump
		-webkit-touch-callout none  /*系统默认菜单被禁用*/
		-webkit-user-select none /*webkit浏览器*/
		-khtml-user-select none /*早期浏览器*/
		-moz-user-select none /*火狐*/
		-ms-user-select none  /*IE10*/
		user-select none
		background rgba(0, 0, 0, 0.5)
		height 100vh
	#svga
		position absolute
		bottom 0
		width 80%
		height 100%
		left 50%
		transform translateX(-50%)
		z-index 9
	.love-tree
		position fixed
		bottom 0
		width 100%
		background #FFE29B
		border-radius 30px 30px 0 0
		.drop-down 
			padding 0.533333rem 0.533333rem 0.346666rem
			margin 0 auto
			width max-content
			img
				width 0.746666rem
				height 0.32rem
		.tips-box 
			display flex
			justify-content space-between
			align-items flex-start
			padding 0 0.1rem 0 0.24rem
			.num
				display flex
				justify-content flex-start
				align-items center
				padding 0 0.48rem 0 0.26rem
				height 0.64rem
				background #FFCA7F
				border-radius 0.32rem
				img
					margin-right 0.08rem
					width 0.54rem
					height 0.54rem
				span
					position relative
					top 1px
					font-size 0.4rem
					color #B67110
					font-weight 500
			.icon 
				display flex
				justify-content space-between
				align-items center
				div
					display flex
					flex-direction column
					justify-content center
					align-items center
					margin-right 0.38rem
					img
						margin-bottom 0.106666rem
						width 0.56rem
						height 0.56rem
					p
						font-size 0.24rem
						color #B67110
						font-weight 500
		.notice-box
			display flex
			justify-content flex-start
			align-items center
			margin-top 0.18rem
			height 0.64rem
			background linear-gradient(to right, #FFB64E, rgba(255, 182, 78, 0.1))
			img
				margin 0 0.2rem 0 0.266666rem
				width 0.48rem
				height 0.48rem
			.notice-swipe
				height 0.64rem
				width 80%
				.notice
					height 0.64rem
					line-height 0.78rem
					text-align left
					p 
						font-size 0.32rem
						color #fff
						span
							margin 0 2px
						.nick-name
							color #8484F8
						.gift
							color #FF7A7A
		.gift-pool
			display flex
			justify-content flex-start
			margin 0.213333rem 0 0 0.133333rem
			img 
				width 1.733333rem
				height 0.693333rem
		.tree-box
			position relative
			.tree
				width 6.613333rem
				height 8.746666rem
			.watering 
				position absolute
				bottom 0.586666rem
				left 50%
				transform translateX(-50%)
				display flex
				justify-content center
				align-items center
				width 3.893333rem
				height 1.386666rem
				background url(../../assets/images/lovetree/btn-bj.png)
				background-size 100% 100%
				img
					width auto
					height 0.746666rem
				p
					position absolute
					top -0.2rem
					right -1.013333rem
					padding 0.16rem 0.266666rem 0.133333rem
					text-align center
					background #FF5555
					font-size 0.266666rem
					color #fff
					border-radius 5px
		.gear-btn 
			display flex
			justify-content space-between
			align-items center
			margin 0.266666rem 1.066666rem 0.853333rem
			div
				width 3.466666rem
				height 1.333333rem
				display flex
				flex-direction column
				justify-content center
				align-items center
				border-radius 0.666666rem
				background #FFC56E
				p
					font-size 0.32rem
					color #fff
					font-weight bold
				.num
					margin-bottom 0.053333rem
					font-size 0.48rem
			.active
				background linear-gradient(40deg, #FF3838, #FF5353)
	.mask
		position absolute
		top 0
		right 0
		bottom 0
		left 0
		background rgba(0, 0, 0, 0.75)
		border-radius 30px 30px 0 0
		z-index 10
		.tips
			margin 1.6rem auto 1.013333rem
			font-size 0.533333rem
			color #fff
			font-weight bold
		.ok-btn
			position absolute
			bottom 1.94rem
			left 50%
			transform translateX(-50%)
			width 4rem
			height 1.013333rem
			line-height 1.05rem
			background #FAB91D
			border-radius 0.506666rem
			font-size 0.48rem
			color #4E3801
			font-weight bold
			letter-spacing 1px
		.pool-btn
			bottom 1.2rem
		.gift-box
			position relative
			top -0.533333rem
			animation giftZoom 0.8s
			.shine
				width 100vw
				height auto
				animation shineRotate 8s linear infinite
			.gift-item
				position absolute
				top 0
				right 0
				bottom 0
				left 0
				display flex
				justify-content center
				align-items center
				.one
					position relative
					top -0.693333rem
					width 6.533333rem
					height 6.4rem
					animation fadeIn 2s
					img
						width 100%
						height 100%
				ul
					position relative
					top -0.16rem
					display flex
					flex-wrap wrap
					justify-content center
					align-items center
					width 7.68rem
					animation fadeIn 2.8s
					li
						padding 0.16rem
						img
							width 1.6rem
							height 1.6rem
			@keyframes shineRotate
				0% { transform: rotate(0deg); }
				50% { transform: rotate(180deg); }
				100% { transform: rotate(360deg); }
		@keyframes giftZoom
			0% { transform: scale(0, 0); }
			100% { transform: scale(1, 1); }
		.gift-pool
			display flex
			flex-wrap wrap
			justify-content center
			align-items center
			position relative
			top -0.32rem
			width 8rem
			margin auto
			animation fadeIn 1s
			li
				margin 0.16rem
				background url(../../assets/images/lovetree/box-bj.png)
				background-size 100% 100%
				img
					width 1.6rem
					height 1.6rem
		.explain
			position relative
			top -0.266666rem
			margin 0 1.066666rem
			padding 0.693333rem 0.55rem 0.746666rem
			background rgba(254, 222, 78, 0.17)
			border-radius 0.4rem
			animation fadeIn 0.6s
			height 7rem
			overflow-y scroll
			p
				font-size 0.4rem
				color #FFD673
				font-weight bold
				line-height 0.72rem
				text-align left
			.chance-list
				margin-top 0.533333rem
				border 1px dashed #FFD673
				border-bottom none
				li
					display flex
					justify-content center
					align-items center
					border-bottom 1px dashed #FFD673
					p
						width 33.33%
						height 0.613333rem
						text-align center
						line-height height 0.613333rem
						font-size 0.293333rem
						color #FFE9B4
						font-weight 500
				.title p
					color #FFB5B5
		.record
			position relative
			top -0.373333rem
			margin 0 1.066666rem
			padding 0.4rem 0 0.533333rem
			background rgba(254, 222, 78, 0.17)
			border-radius 0.4rem
			animation fadeIn 1s
			.record-list
				height 7.733333rem
				overflow-y scroll
				padding 0 0.666666rem
				.van-cell
					padding 0
					background none
				.van-cell::after
					border none
			.record-list::-webkit-scrollbar
				display none
			/deep/.van-list__finished-text
				color #fff !important
			.record-item
				display flex
				justify-content space-between
				align-items center
				padding 0.04rem 0
				p
					font-size 0.4rem
					color #FFD673
					font-weight bold
				img
					width 0.693333rem
					height 0.693333rem
		@keyframes fadeIn
			0% { opacity: 0; }
			100% { opacity: 1; }
</style>
