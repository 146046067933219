<template>
	<div class="padding">
		<div class="rank-list">
			<div v-if="rankList.length > 0" class="top-three">
				<div class="two">
					<div class="head-pic">
						<img class="head-img" :src="imgUrl+rankList[1].avatar" :onerror="errorImage">
						<img class="crown" src="@/assets/images/midyear/rank-2.png">
					</div>
					<p class="name">{{rankList[1].nickName}}</p>
					<p class="num">通话时长：{{rankList[1].callTime}}m</p>
				</div>
				<div class="one">
					<div class="head-pic">
						<img class="head-img" :src="imgUrl+rankList[0].avatar" :onerror="errorImage">
						<img class="crown" src="@/assets/images/midyear/rank-1.png">
					</div>
					<p class="name">{{rankList[0].nickName}}</p>
					<p class="num">通话时长：{{rankList[0].callTime}}m</p>
				</div>
				<div class="three">
					<div class="head-pic">
						<img class="head-img" :src="imgUrl+rankList[2].avatar" :onerror="errorImage">
						<img class="crown" src="@/assets/images/midyear/rank-3.png">
					</div>
					<p class="name">{{rankList[2].nickName}}</p>
					<p class="num">通话时长：{{rankList[2].callTime}}m</p>
				</div>
			</div>
			<div v-if="rankList.length > 0" class="list">
				<van-list v-model="loading" :finished="finished" :immediate-check ='false' finished-text="没有更多了~" @load="onLoad">
					<van-cell v-for="(item, index) in rankList" :key="index">
						<div v-if="index > 2" class="list-item">
							<div class="left">
								<p class="runk-num">{{index + 1}}</p>
								<div class="head-pic">
									<img :src="imgUrl+item.avatar" :onerror="errorImage">
								</div>
								<p class="name">{{item.nickName}}</p>
							</div>
							<p class="num">通话时长：{{item.callTime}}m</p>
						</div>
					</van-cell>
				</van-list>
			</div>
		</div>
		<van-dialog v-model="ruleShow" :showConfirmButton="false">
			<div class="dialog-box">
				<p class="title">排名规则</p>
				<p class="tips">1、活动期间内，取语音视频通话累计时长前68名。</p>
				<p class="tips">2、第一名可获取价值:<span>88.8元</span>特定礼物<span>裸钻1个</span>+价值:<span>666.6元</span>特定礼物<span>小游艇一个</span>+<span>6180免费钻石</span>。</p>
				<p class="tips">3、第二名可获得<span>666.6元</span>特定小礼物<span>小游艇一个</span>+<span>3344免费钻石</span>。</p>
				<p class="tips">4、第三名可获得<span>88.8元</span>特定礼物<span>裸钻一个</span>+<span>1314免费钻石</span>。</p>
				<p class="tips">5、其它前65名参与活动人员，每人赠送<span>99免费钻石</span>。</p>
				<p class="tips">6、本次活动最终解释权归平台所有，在法律允许范围，任何问题以官方解释为准。</p>
				<p @click="ruleShow = false" class="sure-btn">我知道了</p>
			</div>
		</van-dialog>
	</div>
</template>

<script>
	import { getCallRankRecordApi } from '@/request/api'
	export default {
		data() {
			return {
				ruleShow: false,
				loading: false,
				finished: false,
				imgUrl: process.env.VUE_APP_IMG_URL,
				errorImage: 'this.src="' + require('@/assets/images/laborday/default-pic.png') + '"',
				formData: {
					current: 1,
					size: 11
				},
				rankList: []
			}
		},
		methods: {
			initData() {
				this.finished = false
				this.formData = {
					current: 1,
					size: 11
				}
				this.rankList = []
				this.onLoad()
			},
			onLoad() {
				getCallRankRecordApi(this.formData).then(res=> {
					if (this.rankList.length === res.data.total) {
						this.finished = true
						return
					} else {
						this.formData.current ++
						res.data.records.forEach((item, index) => {
							this.rankList.push(item)
						})
						this.loading = false
					}
				}).catch(err => {
					this.finished = true
					this.loading = false
					this.$toast(err.message)
				})
			}
		}
	}
</script>

<style lang="stylus" scoped>
	.dialog-box
		padding 0.5rem 0.533333rem 1rem
		.title
			margin-bottom 0.4rem
			font-size 0.426666rem
			color #333333
			font-weight bold
		.tips
			margin-bottom 2px
			font-size 0.373333rem
			color #666666
			text-align left
			line-height 0.64rem
			span
				margin 0 2px
				color #EE8218
		.sure-btn
			margin-top 0.8rem
			font-size 0.426666rem
			color #fff
			height 1.066666rem
			line-height 1.18rem
			background linear-gradient(90deg, #FFAE57 0%, #F69D3E 100%)
			border-radius 0.533333rem
	.padding
		min-height 5rem
		padding 0 0.373333rem
	.rank-list
		// padding 0.6rem 0
		.top-three	
			display flex
			justify-content space-between
			align-items center
			padding 0.6rem 0 0.5rem
			div
				display flex
				flex-direction column
				justify-content center
				align-items center
				text-align center
				.head-pic 
					position relative
					border-radius 50%
					border 3px solid
					.head-img
						width 100%
						height 100%
						border-radius 50%
					.crown
						position absolute
						top -0.48rem
						right -0.266666rem
						width 0.91rem
						height 0.86rem
				.name
					margin 0.16rem 0
					width 100%
					font-size 0.346666rem
					font-weight bold
					line-height 0.5rem
					white-space nowrap
					overflow hidden
					text-overflow ellipsis
				.num
					width 100%
					font-size 0.266666rem
					font-weight 500
					white-space nowrap
					overflow hidden
					text-overflow ellipsis
			.one
				position relative
				top -0.16rem
				width 36%
				color #F69149
				.head-pic 
					width 2.133333rem
					height 2.133333rem
					border-color #F69149
					.crown
						top -0.48rem
						right -0.213333rem
				.name
					font-size 0.426666rem
			.two
				width 32%
				color #CDCDCD
				.head-pic 
					width 1.813333rem
					height 1.813333rem
					border-color #CDCDCD
			.three
				width 32%
				color #68E5E2
				.head-pic 
					width 1.813333rem
					height 1.813333rem
					border-color #68E5E2
		.list
			padding 0 0.2rem
			max-height 500px
			overflow-y scroll
			.van-cell
				padding 0
				background none
			.van-cell::after
				border none
		.list::-webkit-scrollbar
			display none
		.list-item
			display flex
			justify-content space-between
			align-items center
			padding 0.16rem 0.4rem 0.16rem 0.1rem
			margin-bottom 0.4rem
			border-radius 0.8rem
			background #FFE0AA
			.left
				display flex
				justify-content flex-start
				align-items center
				.runk-num
					font-size 0.48rem
					color #CA7600
					font-weight 500
					width 1rem
					text-align center
				.head-pic 
					position relative
					margin 0 0.24rem 0 0
					img 
						width 1.173333rem
						height 1.173333rem
						border-radius 50%
				.name
					font-size 0.373333rem
					color #845400
					font-weight bold
			.num
				font-size 0.32rem
				color #C38417
				font-weight 500
</style>
