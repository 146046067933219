<template>
	<div class="padding">
		<ul class="sign-list">
			<li v-for="item in signInList" :key="item.id">
				<p class="day">{{item.name}}</p>
				<img :src="item.state == 1 ? item.urlOpen : item.urlOff" alt="">
				<p class="num">{{item.award}}</p>
			</li>
		</ul>
		<div class="get-btn">
			<van-button @click="getResult" type="default" :loading="loading">点击领取</van-button>
		</div>
		<van-dialog v-model="ruleShow" :showConfirmButton="false">
			<div class="dialog-box">
				<p class="title">签到活动规则</p>
				<p class="tips">1、在活动有效期内，新老用户连续登入签到，活动当天赠送<span>语音卡1张</span>，连续签到3天，赠送<span>语音卡3张</span>，连续登陆7天，赠送<span>语音卡5张</span>。</p>
				<p class="tips">2、中途未登录，重新计算。</p>
				<p class="tips">3、本次活动最终解释权归平台所有，在法律允许范围，任何问题以官方解释为准。</p>
				<p @click="ruleShow = false" class="sure-btn">我知道了</p>
			</div>
		</van-dialog>
		<van-dialog v-model="resultShow" :showConfirmButton="false">
			<div v-if="resultShow" class="dialog-box">
				<p class="title">领取成功</p>
				<img :src="signInList[index].urlOpen" alt="">
				<p class="num">{{signInList[index].award}}</p>
				<p @click="resultShow = false" class="sure-btn">我知道了</p>
			</div>
		</van-dialog>
	</div>
</template>

<script>
	import { getActivitySignInListApi, activitySignInApi } from '@/request/api'
	export default {
		data() {
			return {
				ruleShow: false,
				resultShow: false,
				loading: false,
				signInList: [],
				index: 0
			}
		},
		methods: {
			getSignInList() {
				getActivitySignInListApi().then(res => {
					this.signInList = res.data
				}).catch(err => {
					this.$toast(err.message)
				})
			},
			getResult() {
				this.loading = true
				activitySignInApi().then(res => {
					this.resultShow = true
					this.index = res.data - 1
					this.loading = false
					this.getSignInList()
				}).catch(err => {
					this.loading = false
					this.$toast(err.message)
				})
			}
		},
		mounted() {
			this.getSignInList()
		}
	}
</script>

<style lang="stylus" scoped>
	.padding
		padding 0.2rem 0 0.373333rem
	.sign-list
		display flex
		flex-wrap wrap
		justify-content flex-start
		align-items center
		li
			width 25%
			margin-bottom 0.5rem
			.day
				font-size 0.346666rem
				color #F0944B
				font-weight 500
			img
				margin 0.213333rem 0
				width 1.706666rem
				height 1.226666rem
			.num
				font-size 0.293333rem
				color #333333
				font-weight 400
		li:nth-of-type(4)
			margin-right 0
	.get-btn
		padding 1.6rem 0.5rem 0
		button
			width 100%
			font-size 0.426666rem
			color #fff
			height 1.066666rem
			line-height 1.18rem
			background linear-gradient(90deg, #FFAE57 0%, #F69D3E 100%)
			border-radius 0.533333rem
			border none
	.dialog-box
		padding 0.58rem 0.54rem 0.586666rem
		.title
			margin-bottom 1rem
			font-size 0.426666rem
			color #333333
			font-weight bold
		.tips
			font-size 0.373333rem
			color #666666
			text-align left
			line-height 0.64rem
			span
				margin 0 2px
				color #EE8218
		img
			width 3.2rem
			height auto
		.num
			margin 0.16rem 0 0.8rem
			color #333333
			font-size 0.346666rem
			font-weight bold
		.sure-btn
			margin-top 0.5rem
			font-size 0.426666rem
			color #fff
			height 1.066666rem
			line-height 1.18rem
			background linear-gradient(90deg, #FFAE57 0%, #F69D3E 100%)
			border-radius 0.533333rem
</style>
