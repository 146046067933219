<template>
	<div class="lump" :style="lumpBg">
		<div @click="toActivity" class="activity-btn" :style="roundBtn">活动说明</div>
		<div class="num-box">
			<div @click="toRanking" class="explain-btn" :style="squareBtn">充值排行榜》</div>
			<p>充值金额：<span>{{jewelNum}}</span> 钻石</p>
		</div>
		<div class="list-box">
			<div v-for="(item, index) in rewardList" :key="index" class="item">
				<p class="num">活动期间累计充值<span>{{item.recharge}}</span>钻石，即将获得：<img v-if="jewelNum >= item.recharge" src="@/assets/images/newyear/get-icon.png" alt=""></p>
				<div class="card-box">
					<div class="card">
						<img src="@/assets/images/newyear/video-card.png" alt="">
						<p>视频卡X{{item.card}}</p>
					</div>
					<p v-show="item.jewel != 0" class="and">+</p>
					<div v-show="item.jewel != 0" class="card">
						<img src="@/assets/images/newyear/jewel-card.png" alt="">
						<p>{{item.jewel}}赠送钻石</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Cookies from 'js-cookie'
	import { getUserRankApi } from '@/request/api'
	export default {
		data() {
			return {
				lumpBg: {
					background: `#fd4749 url(${require("@/assets/images/newyear/index-bj.png")})`,
					backgroundSize: '100% auto',
					backgroundRepeat: 'no-repeat'
				},
				roundBtn: {
					background: `url(${require("@/assets/images/newyear/round-btn.png")})`,
					backgroundSize: '100% 100%',
				},
				squareBtn: {
					background: `url(${require("@/assets/images/newyear/square-btn.png")})`,
					backgroundSize: '100% 100%',
				},
				rewardList: [
					{ recharge: 490, card: 1, jewel: 0 },
					{ recharge: 990, card: 2, jewel: 50 },
					{ recharge: 1990, card: 3, jewel: 100 },
					{ recharge: 3990, card: 4, jewel: 200 },
					{ recharge: 6990, card: 5, jewel: 300 },
					{ recharge: 9990, card: 6, jewel: 500 },
					{ recharge: 19990, card: 8, jewel: 888 },
					{ recharge: 29990, card: 10, jewel: 1999 },
					{ recharge: 59990, card: 15, jewel: 2999 },
					{ recharge: 100000, card: 30, jewel: 4888 }
				],
				jewelNum: 0,
				rank: 0
			}
		},
		methods: {
			getUserRank() {
				getUserRankApi().then(res => {
					this.rank = res.data.rank
					this.jewelNum = res.data.sumIntegral
				})
			},
			toActivity() {
				this.$router.push({
					path: '/activityExplain'
				})
			},
			toRanking() {
				this.$router.push({
					path: '/ranking',
					query: {
						rank: this.rank
					}
				})
			}
		},
		mounted() {
			let token = this.$route.query.token || 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VySWQiOiJ0b2tlbjozMzI0MTY5IiwiaWF0IjoxNjQ0Mjg4NTk4fQ.yUoBlEq4T9wgBC70yFawTrIdERn-9iRaQ7d2TV7-Ao4'
			Cookies.set('token', token, { expires: 1 })
			this.getUserRank()
		}
	}
</script>

<style lang="stylus" scoped>
	.lump
		position relative
		padding 5.18rem 0 0.38rem
		.activity-btn
			position absolute
			right 0.4rem
			top 0.426666rem
			display flex
			justify-content center
			align-items center
			width 1.9rem
			height 0.6rem
			font-size 0.346666rem
			color #CE2625
		.num-box
			margin 0 0.586666rem 0 0.106666rem
			display flex
			justify-content space-between
			align-items center
			.explain-btn
				display flex
				justify-content center
				align-items center
				padding-left 0.133333rem
				width 3.733333rem
				height 1.76rem
				font-size 0.4rem
				font-weight 800
				color #EE2A2B
			p 
				color #FFFFFF
				font-size 0.373333rem
				span 
					color #FCDB77
		.list-box
			position relative
			top -0.2rem
			margin 0 0.373333rem
			padding 0 0.48rem 0.4rem
			background #FFFFFF
			box-shadow 0px 8px 19px 1px rgba(194, 23, 26, 0.73)
			border-radius 0.48rem
			text-align left
			.item 
				padding-bottom 0.16rem
				.num
					position relative
					padding 0.586666rem 0 0.28rem
					color #333333
					font-size 0.373333rem
					font-weight bold
					span
						margin 0 1px
						color #FF4343
						letter-spacing 1px
					img
						position absolute
						right 0.8rem
						top 0.053333rem
						width 1.653333rem
						height 1.653333rem
				.card-box
					display flex
					justify-content flex-start
					align-items center
					.and
						position relative
						top -0.106666rem
						margin 0 0.18rem
						color #333333
						font-size 0.56rem
						font-weight 500
					.card
						text-align center
						color #000000
						font-size 0.32rem
						font-weight 500
						img
							margin-bottom 0.053333rem
							width 2.506666rem
							height 1.82rem
</style>
