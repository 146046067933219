<template>
	<div class="mask-page">
		<template v-if="!resultPop">
			<div class="main" :style="mainBg">
				<div class="btn-type">
					<p v-for="(item, index) in boxList" 
					   :key="index" 
					   :style="btnType == index ? btnBg1 : btnBg2"
					   @click="changeType(index, item)"
					   >{{item.drawName}}</p>
				</div>
				<p class="tips">开启盲盒100%中奖<br>使用购买钻石可抽奖</p>
				<div class="center-box">
					<img v-show="btnType == 0" @click="routerTo('prize')" class="tips-img" src="@/assets/images/blindbox/hj-tips.png" alt="">
					<img v-show="btnType == 1" @click="routerTo('prize')" class="tips-img" src="@/assets/images/blindbox/zz-tips.png" alt="">
					<img v-show="btnType == 0" class="box-img" src="@/assets/images/blindbox/hj-box.png" alt="">
					<img v-show="btnType == 1" class="box-img" src="@/assets/images/blindbox/zz-box.png" alt="">
					<img @click="routerTo('record')" class="record-img" src="@/assets/images/blindbox/record.png" alt="">
				</div>
				<div class="open-btn">
					<div v-if="btnType == 0 && freeNum > 0">
						<img class="free-icon" src="@/assets/images/blindbox/free-tips.png" alt="">
						<img @click="luckDraw(3)" class="btn-img" src="@/assets/images/blindbox/open-one.png" alt="">
						<p style="text-decoration:line-through">&nbsp;&nbsp;{{btnType == 0 ? 20 : 200}}钻石&nbsp;&nbsp;</p>
					</div>
					<div v-else>
						<img @click="luckDraw(1)" class="btn-img" src="@/assets/images/blindbox/open-one.png" alt="">
						<p>{{btnType == 0 ? 20 : 200}}钻石</p>
					</div>
					<div>
						<img v-show="btnType == 1" class="tips-icon" src="@/assets/images/blindbox/tips-icon.png" alt="">
						<img @click="luckDraw(2)" class="btn-img" src="@/assets/images/blindbox/open-ten.png" alt="">
						<p>{{btnType == 0 ? 200 : 1900}}钻石</p>
					</div>
				</div>
				<div v-if="loading" class="loading">
					<van-loading color="#d1af72" size="40" />
				</div>
			</div>
			<div @click="closePage()" class="close-btn">
				<img src="@/assets/images/blindbox/close-btn.png" alt="">
			</div>
		</template> 
		<transition name="fade">
			<div v-if="resultPop && type == 1" class="result-box" :style="resultBg">
				<img class="tips-img" src="@/assets/images/blindbox/title_04.png" alt="">
				<p class="tips">恭喜获得以下奖励：</p>
				<div class="img-box">
					<img :src="imgBase+prizeUrl" alt="">
				</div>
				<div @click="resultPop = false" class="btn-box">
					<img src="@/assets/images/blindbox/btn_03.png" alt="">
				</div>
			</div>
		</transition>
		<transition name="fade">
			<div v-if="resultPop && type == 2" class="result-ten" :style="resultTen">
				<img class="tips-img" src="@/assets/images/blindbox/title_04.png" alt="">
				<p class="tips">恭喜获得以下奖励：</p>
				<div class="item-list">
					<div v-for="(item, index) in prizeUrl" :key="index" class="item-box">
						<div class="item">
							<div class="img-box">
								<img :src="imgBase+item.prizeUrl" alt="">
							</div>
						</div>
					</div>
				</div>
				<div @click="resultPop = false" class="btn-box">
					<img src="@/assets/images/blindbox/btn_03.png" alt="">
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
	import Cookies from 'js-cookie'
	import { initializeApi, openBlindBoxApi } from '@/request/api'
	export default {
		data() {
			return {
				loading: false,
				token: '',
				lotteryId: 1,
				btnType: 0,
				mainBg: {
					backgroundImage: `url(${require("@/assets/images/blindbox/box-bj.png")})`,
					backgroundSize: '100%'
				},
				btnBg1: {
					backgroundImage: `url(${require("@/assets/images/blindbox/btn_nav_onclick.png")})`,
					backgroundSize: '100%'
				},
				btnBg2: {
					backgroundImage: `url(${require("@/assets/images/blindbox/btn_nav_click.png")})`,
					backgroundSize: '100%'
				},
				boxList: [],
				propsId: '',
				resultPop: false,
				resultBg: {
					backgroundImage: `url(${require("@/assets/images/blindbox/result-bj.png")})`,
					backgroundSize: '100%, 100%'
				},
				resultTen: {
					backgroundImage: `url(${require("@/assets/images/blindbox/result-ten.png")})`,
					backgroundSize: '100%, 100%'
				},
				prizeUrl: '',
				freeNum: '',
				type: 1,
				imgBase: ''
			}
		},
		methods: {
			// 获取初始化数据
			getInitialize() {
				this.loading = true
				initializeApi(this.lotteryId).then(res => {
					this.loading = false
					this.boxList = res.data
					this.propsId = res.data[this.btnType].id
					this.freeNum = res.data[0].freeNum
				}).catch(err => {
					this.$toast(err.message)
				})
			},
			changeType(type, item) {
				this.btnType = type
				this.propsId = item.id
			},
			routerTo(page) {
				if (page == 'prize') {
					this.$router.push({
						path: '/prize',
						query: {
							type: this.btnType,
							lotteryId: this.lotteryId,
							propsId: this.propsId
						}
					})
				} else {
					this.$router.push({
						path: '/record',
						query: {
							lotteryId: this.lotteryId,
							propsId: this.propsId
						}
					})
				}
			},
			closePage() {
				let ua = navigator.userAgent.toLowerCase()
				if (ua.indexOf('android') != -1) {
					androidWebKit.close()
				} else if (ua.indexOf('iphone') != -1) {
					window.webkit.messageHandlers.closeWindow.postMessage("关闭窗口")
				}
			},
			// 开盲盒
			luckDraw(type) {
				this.type = type == 2 ? 2 : 1
				openBlindBoxApi({
					lotteryId: this.lotteryId,
					propsId: this.propsId,
					type
				}).then(res => {
					this.getInitialize()
					this.resultPop = true
					this.prizeUrl = type == 2 ? res.data : res.data[0].prizeUrl
				}).catch(err => {
					this.$toast(err.message)
				})
			}
		},
		mounted() {
			this.getInitialize()
			this.token = this.$route.query.token
			this.lotteryId = this.$route.query.lotteryId
			this.imgBase = process.env.VUE_APP_IMG_URL
			Cookies.set('token', this.token, { expires: 1 })
			
			console.log(this.token)
			console.log(process.env.VUE_APP_TARGET_URL)
			console.log(process.env.NODE_ENV)
		}
	}
</script>

<style lang="stylus" scoped>
.mask-page
	position absolute
	top 0
	left 0
	display flex
	flex-direction column
	justify-content center
	align-items center
	padding 0 0.48rem
	box-sizing border-box
	width 100%
	height 100%
	.main
		position relative
		box-sizing border-box
		padding 0.746666rem 0.533333rem 0.426666rem
		width 100%
		height 13.6rem
		.loading 
			position absolute
			top 0
			right 0
			bottom 0
			left 0
			z-index 10
			display flex
			justify-content center
			align-items center
			background rgba(255, 255, 255, 0.8)
		.btn-type
			display flex
			justify-content space-between
			height 1.333333rem
			p
				display flex
				justify-content center
				align-items center
				width 3.786666rem
				height 1.333333rem
				font-size 0.48rem
				font-weight bold
				color #E8C78C
				letter-spacing 1px
				transition background-image 0.3s linear
		.tips 
			margin-top 0.22rem
			font-size 0.4rem
			color #907357
			font-weight 600
			text-align left
			line-height 0.55rem
		.center-box 
			position relative
			margin 0.586666rem auto 0
			width 7.813333rem
			height 7.813333rem
			img
				position absolute
			.tips-img 
				top -0.56rem
				left 0.133333rem
				width 1.493333rem
				height 4.06rem
			.box-img
				bottom 1.946666rem
				left 50%
				margin-left -2.266666rem
				width 4.533333rem
				height 4.533333rem
			.record-img
				top -0.8rem
				right 0.186666rem
				width 2.613333rem
				height 2.613333rem
		.open-btn 
			position absolute
			bottom 0.7rem
			left 0
			width 100%
			display flex
			justify-content space-between
			align-items center
			div
				position relative
				width 48%
				.btn-img
					width 100%
					height auto
				p 
					text-align center
					font-size 0.453333rem
					color #694F20
					font-weight 600
				.tips-icon
					position absolute
					top -0.52rem
					left 50%
					margin-left -0.906666rem
					width 2.133333rem
					height 0.853333rem
				.free-icon 
					position absolute
					top -0.32rem
					right 0.32rem
					width 0.853333rem
					height 0.693333rem
	.close-btn
		padding-top 0.533333rem
		font-size 0
		img
			width 1.333333rem
			height 1.333333rem
	.result-box
		position absolute
		top 50%
		left 50%
		transform translate(-50%, -50%)
		width calc(100% - 1.493333rem)
		height 10.666666rem
		z-index 99
		.tips-img 
			position absolute
			left -0.533333rem
			top 2px
			width 6.56rem
			height 1.74rem
		.tips
			margin 2.666666rem 0 0.906666rem 0.8rem
			font-size 0.426666rem
			color #5A4217
			font-weight 400
			text-align left
		.img-box
			box-sizing border-box
			padding 0.133333rem
			margin auto
			width 3.2rem
			height 3.2rem
			border 1px solid #B5A585
			background #E3DAC4
			img
				width 100%
				height 100%
		.btn-box 
			position absolute
			bottom 0.5rem
			left 50%
			transform translate(-50%, 0)
			font-size 0
			img 
				width 5.333333rem
				height 1.546666rem
	
	.result-ten
		position absolute
		top 50%
		left 50%
		transform translate(-50%, -50%)
		width calc(100% - 1.4rem)
		height 14.4rem
		z-index 99
		.tips-img 
			position absolute
			left -0.533333rem
			top 12px
			width 6.56rem
			height 1.74rem
		.tips
			margin 2.8rem 0 0.266666rem 0.8rem
			font-size 0.426666rem
			color #5A4217
			font-weight 400
			text-align left
		.btn-box 
			position absolute
			bottom 0.5rem
			left 50%
			transform translate(-50%, 0)
			font-size 0
			img 
				width 5.333333rem
				height 1.546666rem
		.item-list
			position relative
			display flex
			flex-wrap wrap
			justify-content flex-start
			margin 0.05rem 1rem 0.2rem
			// min-height 8rem
			.item-box 
				position relative
				width 33.3%
				padding-top 33.3%
				.item 
					position absolute
					top 0
					left 0
					box-sizing border-box
					padding 0.2rem
					width 100%
					height 100%
					.img-box
						box-sizing border-box
						padding 0.133333rem
						width 100%
						height 100%
						border 1px solid #B5A585
						background #E3DAC4
						img
							width 100%
							height 100%
	.fade-enter-active, .fade-leave-active 
		transition opacity .2s
	.fade-enter, .fade-leave-to 
		opacity 0
</style>
