<template>
	<div class="padding">
		<div v-for="(item, index) in giftList" :key="index" class="gift-item" :class="index == 1 ? 'margin' : ''">
			<div class="top-box">
				<div class="type">
					<p class="name">{{item.showName}}</p>
					<p class="now"><span>¥</span>{{item.price}}</p>
					<p class="old">¥{{item.originalPrice}}</p>
				</div>
				<div class="buy-btn">
					<img v-show="item.buyStatus == 0" @click="buyGiftPack(0, item.rechargeCode, index)" src="@/assets/images/midyear/btn-1.png" alt="">
					<img v-show="item.buyStatus == 1" @click="buyGiftPack(1)" src="@/assets/images/midyear/btn-2.png" alt="">
				</div>
			</div>
			<div class="card-box">
				<div class="card">
					<img :src="item.diamondImageUrl" alt="">
					<p>{{item.diamond}}钻石</p>
				</div>
				<img class="add-icon one" src="@/assets/images/midyear/add-icon.png" alt="">
				<div class="card center-card">
					<img :src="item.giveDiamondImageUrl" alt="">
					<p>{{item.giveDiamond}}免费钻石</p>
				</div>
				<img class="add-icon two" src="@/assets/images/midyear/add-icon.png" alt="">
				<div class="card">
					<img :src="item.videoImageUrl" alt="">
					<p>{{item.videoCard}}张视频卡</p>
				</div>
			</div>
		</div>
		<van-action-sheet v-model="payShow" cancel-text="取消">
			<div class="content">
				<div v-show="type != -1" @click="payWay(1)" class="pay-btn zfb">
					<img src="@/assets/images/midyear/pay-icon-1.png" alt="">
					<p>支付宝</p>
				</div>
				<div v-show="type != -1" @click="payWay(2)" class="pay-btn wx">
					<img src="@/assets/images/midyear/pay-icon-2.png" alt="">
					<p>微<span style="color: #2BDF7B;">无</span>信</p>
				</div>
				<div v-show="type == -1" @click="payWay(3)" class="pay-btn ios">
					<img src="@/assets/images/midyear/pay-icon-3.png" alt="">
					<p>Pay</p>
				</div>
			</div>
		</van-action-sheet>
		<van-dialog v-model="ruleShow" :showConfirmButton="false">
			<div class="dialog-box">
				<p class="title">礼包说明</p>
				<p class="tips">1、每个礼包<span>限购一次</span>。</p>
				<p class="tips">2、本次活动最终解释权归平台所有，在法律允许范围，任何问题以官方解释为准。</p>
				<p @click="ruleShow = false" class="sure-btn">我知道了</p>
			</div>
		</van-dialog>
		<van-dialog v-model="resultShow" :showConfirmButton="false">
			<div class="dialog-box">
				<p class="title">购买成功</p>
				<img v-show="index == 0" src="@/assets/images/midyear/gift-1.png" alt="">
				<img v-show="index == 1" src="@/assets/images/midyear/gift-2.png" alt="">
				<img v-show="index == 2" src="@/assets/images/midyear/gift-3.png" alt="">
				<p @click="resultShow = false" class="sure-btn">我知道了</p>
			</div>
		</van-dialog>
	</div>
</template>

<script>
	import { get618ActivityConfigApi } from '@/request/api'
	export default {
		data() {
			return {
				payShow: false,
				ruleShow: false,
				resultShow: false,
				giftList: [],
				code: '',
				index: 0,
				deviceInfo: {},
				type: ''
			}
		},
		methods: {
			getActivityInfo() {
				get618ActivityConfigApi().then(res =>{
					this.giftList = res.data
				}).catch(err => {
					this.$toast(err.message)
				})
			},
			buyGiftPack(state, code, index) {
				if (state == 1) {
					this.$toast('限购一次，您已购买~')
				} else {
					this.payShow = true
					this.code = code
					this.index = index
				}
			},
			// 调用安卓支付
			payWay(type) {
				try {
					androidWebKit.payNumber(type, this.code, 'json')
				} catch (error) {
					if (error && error.toString() == 'ReferenceError: androidWebKit is not defined') {
						this.$dialog.alert({confirmButtonText: '我知道了', message: '请您升级APP，如有疑问请咨询在线客服'})
					} else {
						this.$dialog.alert({confirmButtonText: '我知道了', message: '请您升级APP，如有疑问请咨询在线客服..'})
					}
				}
			},
			// 安卓调用传入支付结果
			getPayResult(res) {
				let resArr = res.split('-')
				// this.$toast(resArr[1])
				if (resArr[0] == 'true') {
					this.payShow = false
					this.resultShow = true
					this.getActivityInfo()
					if (this.index == 0) {
						this.buryClick('至尊礼包购买成功', 'Purchaseofthesupremegiftbagsucceeded_male_click')
					} else if (this.index == 1) {
						this.buryClick('狂欢礼包购买成功', 'Carnivalgiftbagpurchasedsuccessfully_male_click')
					} else {
						this.buryClick('回馈礼包购买成功', 'Feedbackgiftbagpurchasedsuccessfully_male_click')
					}
				} else {
					this.$toast('支付失败')
				}
			},
			// 调用埋点方法
			buryClick(name, type) {
				if (this.deviceInfo.indexOf('android') != -1) {
					try {
						androidWebKit.onclikType(name, type)
					} catch (error) {
						console.log(error)
					}
				}
			}
		},
		mounted() {
			this.deviceInfo = navigator.userAgent.toLowerCase()
			this.type = this.deviceInfo.indexOf('android')
		    // 将要给原生调用的方法挂载到 window 上面
		    window.getPayResult = this.getPayResult
			this.getActivityInfo()
		}
	}
</script>

<style lang="stylus" scoped>
	.padding
		padding 0 0.373333rem
	.margin
		margin 0.4rem 0
		padding 0.4rem 0
		border-top 1px dashed #FFC5AA
		border-bottom 1px dashed #FFC5AA
	.top-box
		display flex
		justify-content space-between
		align-items center
		margin-bottom 0.426666rem
		.type
			display flex
			justify-content flex-start
			align-items center
			.name
				color #333333
				font-size 0.4rem
				font-weight bold
			.now
				margin 0 0.106666rem
				color #DD3F0A
				font-size 0.48rem
				font-weight bold
				span
					margin-right 1px
					font-size 0.373333rem
			.old
				color #666666
				font-size 0.32rem
				font-weight 400
				text-decoration line-through
		.buy-btn
			font-size 0
			img
				width 1.84rem
				height 0.693333rem
	.card-box
		display flex
		justify-content flex-start
		align-items center
		.card
			// width 2.133333rem
			img
				width 1.706666rem
				height 1.226666rem
			p
				color #333333
				font-size 0.293333rem
				line-height 0.5rem
				font-weight 400
		.add-icon
			position relative
			top -0.22rem
			width 0.32rem
			height 0.32rem
		.one
			margin 0 0.106666rem 0 0.266666rem
		.two
			margin 0 0.3rem 0 0.106666rem
	.center-card
		width 2.133333rem
	.content
		padding 0.72rem
		.pay-btn
			display flex
			justify-content center
			align-items center
			height 1.333333rem
			border-radius 10px
			box-sizing border-box
			img
				width 0.8rem
				height 0.8rem
				margin-right 0.48rem
			p
				font-size 0.4rem
				color #fff
				font-weight 500
		.zfb
			margin-bottom 0.266666rem
			background #429BFF
		.wx
			background #2BDF7B
		.ios
			background #fff
			border 1px solid #000000
			p
				color #000000
	.dialog-box
		padding 0.5rem 0.533333rem 0.586666rem
		.title
			margin-bottom 1rem
			font-size 0.426666rem
			color #333333
			font-weight bold
		.tips
			padding 0 0.32rem
			margin-bottom 2px
			font-size 0.373333rem
			color #666666
			text-align left
			line-height 0.64rem
			span
				margin-left 2px
				color #EE8218
		.sure-btn
			margin-top 1.44rem
			font-size 0.426666rem
			color #fff
			height 1.066666rem
			line-height 1.18rem
			background linear-gradient(90deg, #FFAE57 0%, #F69D3E 100%)
			border-radius 0.533333rem
		img
			width 3.946666rem
			height 3.253333rem
</style>
