import axios from "axios"
import Cookies from 'js-cookie'

// 创建一个 axios 实例
const service = axios.create({
    baseURL: process.env.NODE_ENV == 'development' ? '/v1' : process.env.VUE_APP_TARGET_URL+'/v1',
    timeout: 30000 // 请求超时时间，单位毫秒
})

axios.defaults.withCredentials = true

// 请求拦截器
service.interceptors.request.use(
    config => {
        const token = Cookies.get('token')
        if (token) {
            config.headers['Authorization'] = token
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

//响应拦截器即异常处理
service.interceptors.response.use(
	response => {
		const code = response.data.code
		if (code == 200) {
			return response.data
		} else {
			return Promise.reject(response.data || { msg: '未知错误', data: [] })
		}
	}, 
	error => {
		return Promise.reject(error)
	}
)

export default service