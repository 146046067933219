<template>
	<div class="lump" :style="lumpBg">
		<div class="rank-box">
			<div class="tab-box">
				<div class="tab">
					<p @click="changeTab(1)" :class="tabNum == 1 ? 'active' : ''">富豪榜</p>
					<p @click="changeTab(0)" :class="tabNum == 0 ? 'active' : ''">女神榜</p>
				</div>
			</div>
			<img @click="routerTo" class="explain" src="@/assets/images/laborday/ask-icon.png" alt="">
			<img class="gold" src="@/assets/images/laborday/gold.png" alt="">
			<p class="rank-num">当前排名： {{rankNum}}</p>
			<div class="runk-list">
				<div v-if="rankList.length > 0" class="top-three">
					<div class="two">
						<div class="head-pic">
							<img class="head-img" :src="imgUrl+rankList[1].avatar" :onerror="errorImage">
							<img class="crown" src="@/assets/images/laborday/crown-02.png">
						</div>
						<p class="name">{{rankList[1].nickName}}</p>
						<p class="num">开心指数：{{rankList[1].sumIntegral}}</p>
					</div>
					<div class="one">
						<div class="head-pic">
							<img class="head-img" :src="imgUrl+rankList[0].avatar" :onerror="errorImage">
							<img class="crown" src="@/assets/images/laborday/crown-01.png">
						</div>
						<p class="name">{{rankList[0].nickName}}</p>
						<p class="num">开心指数：{{rankList[0].sumIntegral}}</p>
					</div>
					<div class="three">
						<div class="head-pic">
							<img class="head-img" :src="imgUrl+rankList[2].avatar" :onerror="errorImage">
							<img class="crown" src="@/assets/images/laborday/crown-03.png">
						</div>
						<p class="name">{{rankList[2].nickName}}</p>
						<p class="num">开心指数：{{rankList[2].sumIntegral}}</p>
					</div>
				</div>
				<ul v-if="rankList.length > 0" class="list">
					<li v-for="(item, index) in rankList" :key="index">
						<div v-if="index > 2 && index < 10" class="item">
							<div class="left">
								<p class="runk-num">{{index + 1}}</p>
								<div class="head-pic">
									<img :src="imgUrl+item.avatar" :onerror="errorImage">
									<!-- <span :class="tabNum == 1 ? 'boy' : 'girl'">30岁</span> -->
								</div>
								<p class="name">{{item.nickName}}</p>
							</div>
							<p class="num">开心指数：{{item.sumIntegral}}</p>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
	import qs from 'qs'
	import Cookies from 'js-cookie'
	import { getLabordayRankApi, getMyRankApi } from '@/request/api'
	export default {
		data() {
			return {
				tabNum: 1,
				lumpBg: {
					background: `#DD4743 url(${require("@/assets/images/laborday/bg.png")})`,
					backgroundSize: '100% auto',
					backgroundRepeat: 'no-repeat'
				},
				errorImage: 'this.src="' + require('@/assets/images/laborday/default-pic.png') + '"',
				rankList: [],
				imgUrl: '',
				rankNum: 0
			}
		},
		methods: {
			changeTab(num) {
				this.tabNum = num
				this.getRankList()
				this.getMyRank()
			},
			routerTo() {
				if (this.tabNum == 1) {
					this.$router.push({
						path: '/richExplain'
					})
				} else {
					this.$router.push({
						path: '/goddessExplain'
					})
				}
			},
			getRankList() {
				let data = qs.stringify({ type: this.tabNum })
				getLabordayRankApi(data).then(res => {
					this.rankList = res.data
					this.rankList.forEach((item, index) => {
						let value = this.numFilters(item.sumIntegral)
						this.$set(this.rankList[index], 'sumIntegral', value)
					})
					console.log(this.rankList)
				})
			},
			getMyRank() {
				let data = qs.stringify({ type: this.tabNum })
				getMyRankApi(data).then(res => {
					this.rankNum = res.data.rank
				})
			},
			numFilters(value) {
				let num
				if (value > 9999) { // 大于9999显示x.xx万
				    num = (Math.floor(value / 1000) / 10) + '万'
				} else if ( value > 0 && value <= 9999) {
				   num = value
				} else if (value < -9999) {//小于-9999显示-x.xx万
				    num = -(Math.floor(Math.abs(value)/1000)/10)+'万'
				}
				return num
			}
		},
		mounted() {
			this.imgUrl = process.env.VUE_APP_IMG_URL
			let token = this.$route.query.token || 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VySWQiOiJ0b2tlbjo5MDUwODYiLCJpYXQiOjE2NTExMzQ4NTV9.mFnMScdWPL-5dvhij9p6MRKCCAIyFJTlmg1CvpZzeBU'
			Cookies.set('token', token, { expires: 1 })
			this.getRankList()
			this.getMyRank()
		}
	}
</script>

<style lang="stylus" scoped>
	.lump
		padding 6.88rem 0.346666rem 1.066666rem
		box-sizing border-box
		min-height 100vh
	.rank-box
		position relative
		padding 0.4rem 0 0.2rem
		background  #FFF0D4
		border-radius 12px
		box-shadow 0px 3px 16px 0px rgba(230, 96, 60, 0.4), 0px 0px 45px 0px rgba(213, 58, 54, 0.4) inset
		.tab-box
			width 5.333333rem
			border-radius 25px
			background #FFC0B6
			margin auto
			.tab
				display flex
				justify-content center
				align-items center
				p
					width 50%
					height 0.67rem
					line-height  0.76rem
					font-size 0.346666rem
					color #FAF2D2
					font-weight bold
				.active
					border-radius 25px
					background #E95038
		.explain
			position absolute
			right 0.266666rem
			top 0.64rem
			width 0.453333rem
			height 0.453333rem
		.gold
			position absolute
			bottom -0.4rem
			left 0.96rem
			width 0.773333rem
			height 0.693333rem
		.rank-num
			padding 0.426666rem 0.3rem 0 0
			text-align right
			font-size 0.346666rem
			color #DC463A
			font-weight bold
	.runk-list
		padding 0.8rem 0.266666rem 0
		.top-three	
			display flex
			justify-content space-between
			align-items center
			div
				display flex
				flex-direction column
				justify-content center
				align-items center
				text-align center
				.head-pic 
					position relative
					border-radius 50%
					border 1px solid
					.head-img
						width 100%
						height 100%
						border-radius 50%
					.crown
						position absolute
						top -0.346666rem
						right 0px
						width 0.666666rem
						height 0.586666rem
				.name
					margin 0.16rem 0
					width 100%
					font-size 0.346666rem
					font-weight bold
					line-height 0.5rem
					white-space nowrap
					overflow hidden
					text-overflow ellipsis
				.num
					width 100%
					font-size 0.266666rem
					font-weight 500
					white-space nowrap
					overflow hidden
					text-overflow ellipsis
			.one
				position relative
				top -0.266666rem
				width 36%
				color #E9513B
				.head-pic 
					width 2.133333rem
					height 2.133333rem
					border-color #E9513B
					.crown
						top -0.4rem
						width 0.8rem
						height 0.72rem
				.name
					font-size 0.426666rem
			.two
				width 32%
				color #D94A9B
				.head-pic 
					width 1.813333rem
					height 1.813333rem
					border-color #D94A9B
			.three
				width 32%
				color #F2933D
				.head-pic 
					width 1.813333rem
					height 1.813333rem
					border-color #F2933D
		.list
			padding 0.533333rem 0.32rem
			li
				.item
					display flex
					justify-content space-between
					align-items center
					padding 0.16rem 0.4rem 0.16rem 0.1rem
					margin-bottom 0.426666rem
					border-radius 0.8rem
					background #FFE0AA
					.left
						display flex
						justify-content flex-start
						align-items center
						.runk-num
							font-size 0.48rem
							color #CA7600
							font-weight 500
							width 1rem
						.head-pic 
							position relative
							margin 0 0.24rem 0 0
							img 
								width 1.173333rem
								height 1.173333rem
								border-radius 50%
							span
								position absolute
								top 0
								right -0.24rem
								padding 2px 3px 1px
								width max-content
								font-size 0.24rem
								line-height 0.24rem
								color #fff
								border-radius 8px
							.boy
								background #64A6F8
							.girl
								background #FF8AA3
						.name
							font-size 0.373333rem
							color #845400
							font-weight bold
					.num
						font-size 0.32rem
						color #C38417
						font-weight 500
</style>
