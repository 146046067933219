<template>
	<div class="lump">
		<p @click="openRule" class="rule-tag">
			<span v-show="tabNum == 1">签到规则</span>
			<span v-show="tabNum == 2">礼包说明</span>
			<span v-show="tabNum == 3">排名规则</span>
		</p>
		<p class="active-time">2022/6/18 至 2022/6/24 </p>
		<div class="banner">
			<img src="@/assets/images/midyear/main-banner.png" alt="">
		</div>
		<div class="main">
			<div class="tab">
				<div @click="changeTab(1)" class="item active">
					<img v-if="tabNum == 1" src="@/assets/images/midyear/tab-1.png" alt="">
					<img v-else src="@/assets/images/midyear/tab-4.png" alt="">
				</div>
				<div @click="changeTab(2)" class="item">
					<img v-if="tabNum == 2" src="@/assets/images/midyear/tab-2.png" alt="">
					<img v-else src="@/assets/images/midyear/tab-5.png" alt="">
				</div>
				<div @click="changeTab(3)" class="item">
					<img v-if="tabNum == 3" src="@/assets/images/midyear/tab-3.png" alt="">
					<img v-else src="@/assets/images/midyear/tab-6.png" alt="">
				</div>
			</div>
			<div class="content">
				<sign-in ref="signIn" v-show="tabNum == 1" />
				<gift-pack ref="giftPack" v-show="tabNum == 2" />
				<rank-list ref="rankList" v-show="tabNum == 3" />
			</div>
		</div>
	</div>
</template>

<script>
	import Cookies from 'js-cookie'
	import signIn from './components/signIn.vue'
	import giftPack from './components/giftPacks.vue'
	import rankList from './components/rankList.vue'
	export default {
		components: { signIn, giftPack, rankList },
		data() {
			return {
				tabNum: 2,
				token: ''
			}
		},
		methods: {
			changeTab(num) {
				this.tabNum = num
				if (num == 1) {
					this.buryClick('免费领奖', 'Receivetheawardfreeofcharge_male_click')
				} else if (num == 2) {
					this.buryClick('618大礼包', '618giftbag_male_click')
				} else {
					this.$refs['rankList'].initData()
					this.buryClick('排行榜', 'RankingList_male_click')
				}
			},
			openRule() {
				if (this.tabNum == 1) {
					this.$refs['signIn'].ruleShow = true
				}else if (this.tabNum == 2) {
					this.$refs['giftPack'].ruleShow = true
				} else {
					this.$refs['rankList'].ruleShow = true
				}
			},
			// 调用埋点方法
			buryClick(name, type) {
				let ua = navigator.userAgent.toLowerCase()
				if (ua.indexOf('android') != -1) {
					try {
						androidWebKit.onclikType(name, type)
					} catch (error) {
						if (error && error.toString() == 'ReferenceError: androidWebKit is not defined') {
							this.$dialog.alert({confirmButtonText: '我知道了', message: '请您升级APP，如有疑问请咨询在线客服'})
						} else {
							this.$dialog.alert({confirmButtonText: '我知道了', message: '请您升级APP，如有疑问请咨询在线客服..'})
						}
					}
				}
			}
		},
		mounted() {
			this.token = this.$route.query.token
			Cookies.set('token', this.token, { expires: 1 })
		}
	}
</script>

<style lang="stylus" scoped>
	.lump
		display flex
		flex-direction column
		position relative
		background #FFCEA6
		min-height 100vh
		.rule-tag
			position absolute
			right 0
			top 1.6rem
			padding 0.22rem 0.2rem 0.2rem 0.28rem
			background rgba(0, 0, 0, 0.6)
			border-radius 14px 0 0 14px
			font-size 0.32rem
			color #FFF0D4
		.active-time
			position absolute
			top 3.52rem
			left 50%
			margin-left -2.533333rem
			width 5.066666rem
			height 0.613333rem
			line-height 0.68rem
			background #DE6D39
			font-size 0.293333rem
			color #FFFAD5
			border-radius 0.32rem
	.banner
		font-size 0
		img
			width 100%
	.main
		padding 0.373333rem 0 
		background linear-gradient(to bottom, #F6BA77, #F7BE7F)
		box-shadow 0px 0px 20px #ec7c40 inset
		border-radius 7px 7px 0 0
		flex 1
		display flex
		flex-direction column
		.tab
			padding 0 0.266666rem
			display flex
			justify-content space-between
			align-items center
			.item img
				width 2.853333rem
				height 0.986666rem
			.active img
				animation tab-zoom 1.4s linear infinite
		@keyframes tab-zoom
			0% { transform: scale(0.9, 0.9); }
			50% { transform: scale(1, 1); }
			100% { transform: scale(0.9, 0.9); }
		.content
			margin 0.373333rem 0.32rem 0
			padding 0.48rem 0
			background #FDF3CE
			border-radius 0.266666rem
			flex 1
</style>
